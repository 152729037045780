import React from "react";
import styled from "styled-components";
import GlobalStyle from "./styles/global";
import Design from "./components/Design";
import ReactFullpage from "@fullpage/react-fullpage";

const Heading = styled.div`
    text-align: center;
    color: #fff;
    padding: 0px 10px;
    h1 {
        margin: 0;
        padding: 0;
        font-size: 64px;
        color: #b4e4cc;
        margin-bottom: 10px;
    }
    h2 {
        margin: 0;
        padding: 0;
        font-size: 42px;
        color: #68ca99;
        span {
            color: #fff;
        }
    }
    .wrapper {
        max-width: 880px;
        margin: 0 auto;
    }

    @media (max-width: 1366px) {
        h1 {
            font-size: 48px;
            line-height: 60px;
        }
        h2 {
            font-size: 32px;
            line-height: 30px;
        }
        .wrapper {
            max-width: 720px;
        }
    }

    @media (max-width: 980px) {
        .wrapper {
            max-width: 500px;
        }
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 40px;
            line-height: 50px;
        }
        h2 {
            font-size: 28px;
        }
    }

    @media (max-width: 520px) {
        h1 {
            font-size: 36px;
            line-height: 40px;
        }
        .wrapper {
            max-width: 85%;
        }
    }
`;

const Button = styled.button`
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;
    border: dashed 2px rgba(255, 255, 255, 0.23);
    border-radius: 8px;
    cursor: pointer;
    height: 80px;
    line-height: 60px;
    padding: 0px 25px;
    outline: none;
    text-decoration: none;
    display: inline-flex;
    line-height: 1;
    align-items: center;
    &:active,
    &:focus,
    &:visited,
    &:hover {
        outline: none;
        color: #fff;
    }
    &:hover {
        border-color: rgba(255, 255, 255, 0.8);
    }

    ${(props) =>
        props.isActive &&
        "border-color: rgba(255,255,255,.8);background-color: rgba(255,255,255,0.2);"}

    img {
        margin-right: 20px;
        max-width: 50px;
        height: 50px;
        object-fit: contain;
    }

    @media (max-width: 980px) {
        font-size: 24px;
        padding: 0px 15px;
        height: 60px;
    }

    @media (max-width: 768px) {
        font-size: 18px;
        padding: 0px 10px;
        height: 50px;
        img {
            margin-right: 10px;
            max-width: 40px;
            height: 40px;
        }
    }
`;

const Actions = styled.div`
    text-align: center;
    padding-top: 20px;
    & ${Button} {
        margin: 5px;
    }

    .small {
        margin-top: 20px;
        font-size: 24px;
        height: 65px;
    }
`;

const rules = {
    "6 PIES": {
        grande: {
            flores: 20,
            adornos: 125,
            rollos: 2,
            extensiones: 7,
            angel: 1,
        },
        pencil: {
            flores: 5,
            adornos: 31,
            rollos: 1,
            extensiones: 1,
            angel: 1,
        },
    },
    "7 PIES": {
        grande: {
            flores: 30,
            adornos: 150,
            rollos: 3,
            extensiones: 7,
            angel: 1,
        },
        pencil: {
            flores: 8,
            adornos: 38,
            rollos: 1,
            extensiones: 2,
            angel: 1,
        },
    },
    "8 PIES": {
        grande: {
            flores: 50,
            adornos: 160,
            rollos: 4,
            extensiones: 10,
            angel: 1,
        },
        pencil: {
            flores: 13,
            adornos: 40,
            rollos: 1,
            extensiones: 3,
            angel: 1,
        },
    },
    "9 PIES": {
        grande: {
            flores: 60,
            adornos: 175,
            rollos: 6,
            extensiones: 12,
            angel: 1,
        },
        pencil: {
            flores: 15,
            adornos: 44,
            rollos: 2,
            extensiones: 3,
            angel: 1,
        },
    },
    "12 PIES": {
        grande: {
            flores: 80,
            adornos: 225,
            rollos: 10,
            extensiones: 22,
            angel: 1,
        },
        pencil: {
            flores: 20,
            adornos: 56,
            rollos: 3,
            extensiones: 6,
            angel: 1,
        },
    },
};

const typeImage = {
    grande: "full.svg",
    delgado: "slim.svg",
    pencil: "pencil.svg",
};
/*eslint-disable no-undef*/
const Fullpage = () => {
    const [tree, setTree] = React.useState(null);
    const [type, setType] = React.useState(null);

    const handleSetTree = (newTree, fullpageApi) => {
        fullpageApi.moveSectionDown();
        setTree(newTree);
        if (typeof analytics !== "undefined") {
            analytics.track("Set Tree", {
                tree: newTree,
            });
        }
    };
    const handleSetType = (newType, fullpageApi) => {
        fullpageApi.moveSectionDown();
        setType(newType);
        analytics.track("Set Type", {
            tree: newType,
        });
        analytics.track("Calculated XMas tree", {
            fullness: "lleno",
            type,
            tree,
        });
    };

    const getCount = (item) => {
        if (tree && type) {
            return rules[tree][type][item];
        }
        return null;
    };

    return (
        <ReactFullpage
            scrollingSpeed={1000}
            scrollHorizontally={false}
            dragAndMove={false}
            keyboardScrolling={false}
            render={({ state, fullpageApi }) => {
                if (fullpageApi) fullpageApi.setAllowScrolling(false, "down");
                return (
                    <ReactFullpage.Wrapper>
                        <div className="section">
                            <Heading>
                                <h1>Calculadora de adornos</h1>
                                <h2>de Árbol de Navidad</h2>
                            </Heading>
                            <Actions>
                                <Button
                                    onClick={() =>
                                        fullpageApi.moveSectionDown()
                                    }
                                >
                                    Comenzar
                                </Button>
                            </Actions>
                        </div>
                        <div className="section">
                            <Heading>
                                <h1>1.</h1>
                                <h2>¿Qué tan alto es tu árbol?</h2>
                            </Heading>
                            <Actions>
                                {Object.keys(rules).map((index) => (
                                    <Button
                                        isActive={index === tree}
                                        key={`tree${index}`}
                                        onClick={() =>
                                            handleSetTree(index, fullpageApi)
                                        }
                                    >
                                        {index}
                                    </Button>
                                ))}
                            </Actions>
                        </div>

                        <div className="section">
                            <Heading>
                                <h1>2.</h1>
                                <h2>¿Qué forma tiene tu árbol?</h2>
                            </Heading>
                            <Actions>
                                {tree
                                    ? Object.keys(rules[tree]).map((index) => (
                                          <Button
                                              isActive={index === type}
                                              key={`type${index}`}
                                              onClick={() =>
                                                  handleSetType(
                                                      index,
                                                      fullpageApi
                                                  )
                                              }
                                          >
                                              <img
                                                  src={`./${typeImage[index]}`}
                                                  alt=""
                                              />
                                              {index}
                                          </Button>
                                      ))
                                    : null}
                            </Actions>
                        </div>

                        <div className="section">
                            <Heading>
                                <div className="wrapper">
                                    <h1>
                                        Para un árbol {type} de {tree} Santini
                                        necesitas
                                        {/* For a 5ft, slim tree that's normal */}
                                    </h1>
                                    <h2>
                                        <span>{getCount("flores")}</span>{" "}
                                        flores, <span>{getCount("adornos")}</span>{" "}
                                        adornos, <span>{getCount("rollos")}</span>{" "}
                                        rollos, <span>{getCount("extensiones")}</span>{" "}
                                        extensiones y <span>{getCount("angel")}</span>{" "}
                                        ángel
                                    </h2>
                                </div>
                            </Heading>
                            <Actions>
                                <Button as="a" href="https://www.santinichristmas.com" className="small">
                                    Volver a Santini
                                </Button>
                            </Actions>
                        </div>
                    </ReactFullpage.Wrapper>
                );
            }}
        />
    );
};

function App(props) {
    console.log(props);

    return (
        <>
            {/* <Reset /> */}
            <GlobalStyle />

            <Design></Design>
            <Fullpage></Fullpage>
        </>
    );
}

export default App;
