import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  html {
    font-size: 16px;
    font-family: 'Libre Baskerville', cursive;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
            text-size-adjust: 100%;
    }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }
  a {
    text-decoration: none;
    color: rgb(81, 116, 223);
  }
  li {
    list-style: none;
  }
  body {
    color: #404040;
    overflow-x: hidden;
  }


  html, body, #root {
    height: 100%;
    min-height: 800px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
`;
