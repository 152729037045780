import React from "react";
import styled from "styled-components";

const Background = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -2;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

function Design() {
    return (
        <>
            <Background>
                <picture>
                    <source media="(orientation: portrait)" srcset="bg-mobile.jpg" />
                    <source media="(max-width: 1366px)" srcset="bg-medium.jpg" />
                    <img src="./bg-large.jpg" alt="" />
                </picture>
            </Background>
        </>
    );
}

export default Design;
